<template>
  <v-dialog
    v-model="isEditMemberType"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('addMemberType') }}
      </v-card-title>
      <v-form
        ref="formEditMemberType"
        @submit.prevent="updateMemberType"
      >
        <v-card-text>
          <v-text-field
            v-model="dataEditLocal.name"
            outlined
            dense
            :rules="[required]"
            :label="$t('nameMemberType')"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-edit-member-type',false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import memberType from '@/api/member/memberType'
import { required } from '@/@core/utils/validation'
import store from '@/store'

export default {
  model: {
    prop: 'isEditMemberType',
    event: 'update:is-edit-member-type',
  },
  props: {
    isEditMemberType: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditMemberType = ref(null)
    const loading = ref(false)
    const dataEditLocal = ref({})

    const { memberTypeUpdate } = memberType

    const updateMemberType = () => {
      loading.value = true
      memberTypeUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-member-type', false)
        emit('onUpdate')
        loading.value = false
      })
    }

    watch(() => props.isEditMemberType, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
      }
    })

    return {
      updateMemberType,
      required,
      dataEditLocal,
      formEditMemberType,
      loading,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
