<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <router-link
          class="font-weight-medium text-decoration-none me-2"
          :to="{ name: 'Member-Card'}"
        >
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("back") }}</span>
          </v-tooltip>
        </router-link> {{ $t('memberType') }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewMemberType = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('addMemberType') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          small
          @click="isAddNewMemberType = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-select
            v-model.trim="status"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          {{ index+1 }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <StatusBlock :status="String(item.status)" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item;isEditMemberType = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="id=item.id;statusUpdate = item.status ;isUpdateStatus= true"
                v-on="on"
              >
                <v-icon>{{ item.status==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.status==1 ?$t("suspend"):$t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewMemberType
      v-model="isAddNewMemberType"
      @onAdd="fetchDataTable"
    />
    <EditMemberType
      v-model="isEditMemberType"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ statusUpdate ==1?$t('confirmSuspendMemberType'):$t('cancelSuspendMemberType') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processUpdate"
            :disabled="processUpdate"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiArrowLeftBold, mdiAlertOutline,
} from '@mdi/js'
import useMemberType from './useMemberType'
import AddNewMemberType from './AddNewMemberType.vue'
import EditMemberType from './EditMemberType.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'

export default {
  components: {
    AddNewMemberType,
    EditMemberType,
    StatusBlock,
  },
  setup() {
    return {
      ...useMemberType(),
      icons: {
        mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiArrowLeftBold, mdiAlertOutline,
      },
    }
  },
}
</script>
