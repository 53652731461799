<template>
  <v-dialog
    v-model="isAddNewMemberType"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('addMemberType') }}
      </v-card-title>
      <v-form
        ref="formAddMemberType"
        @submit.prevent="addMemberType"
      >
        <v-card-text>
          <v-text-field
            v-model="name"
            outlined
            autofocus
            dense
            :rules="[required]"
            :label="$t('nameMemberType')"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-add-new-member-type',false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import memberType from '@/api/member/memberType'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewMemberType',
    event: 'update:is-add-new-member-type',
  },
  props: {
    isAddNewMemberType: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const name = ref('')
    const formAddMemberType = ref(null)
    const loading = ref(false)

    const { memberTypeAdd } = memberType
    const addMemberType = () => {
      const isFormValid = formAddMemberType.value.validate()
      if (!isFormValid) return
      loading.value = true
      memberTypeAdd({ name: name.value }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-member-type', false)
        emit('onAdd')
        loading.value = false
      })
    }

    return {
      required,
      addMemberType,
      name,
      formAddMemberType,
      loading,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
