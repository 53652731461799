import basicStatus from '@/@fake-db/data/basicStatus.json'
import memberType from '@/api/member/memberType'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useMemberType() {
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'id',
    },
    {
      text: i18n.t('name'),
      value: 'name',
    },
    {
      text: i18n.t('status'),
      value: 'status',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ])
  const options = ref({})
  const loading = ref(false)
  const isAddNewMemberType = ref(false)
  const isEditMemberType = ref(false)
  const dataEdit = ref({})
  const dataTableList = ref([])
  const statusList = ref(basicStatus.data)
  const status = ref(1)
  const searchtext = ref('')
  const id = ref('')
  const statusUpdate = ref('')
  const isUpdateStatus = ref(false)
  const processUpdate = ref(false)
  const { memberTypeList, memberTypeStatusUpdate } = memberType
  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    memberTypeList({
      searchtext: searchtext.value,
      status: status.value == 2 ? '0' : status.value == 1 ? '1' : '',
    }).then(res => {
      dataTableList.value = res
    })
  }

  const updateStatus = () => {
    processUpdate.value = true
    memberTypeStatusUpdate({
      id: id.value,
      status: statusUpdate.value == 1 ? '0' : '1',
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      fetchDataTable()
      isUpdateStatus.value = false
      processUpdate.value = false
    })
  }

  watch([searchtext, status], () => {
    fetchDataTable()
  })

  return {
    dataTableList,
    columns,
    options,
    loading,
    isUpdateStatus,
    statusList,
    status,
    searchtext,
    isAddNewMemberType,
    isEditMemberType,
    dataEdit,
    processUpdate,
    id,
    statusUpdate,
    fetchDataTable,
    updateStatus,
  }
}
